import { IHousing } from "../models/housing";
import { isObjectFunction } from "./helpers";
import { HousingCallback } from "../models/config";
import config from "react-global-configuration";

export type OnStringCallback = (
    housing: IHousing,
    information: IBankInformation,
) => string;

export interface IBankInformation {
    id: string;
    label?: string;
    campaignId?: string;
    description?: string | OnStringCallback;
    className?: string;
    url?: string | OnStringCallback;
}

enum Bank {
    Nordea = "nordea",
    Danskebank = "danskebank",
}

export interface IOffer {
    header?: string;
    description?: string;
    onClick: HousingCallback;
}

export interface IInsight {
    id: string;
    configuration?: Array<IBankInformation>;
}

export const BANK_OPTIONS: IBankInformation[] = [
    {
        id: Bank.Nordea,
        label: "Nordea",
        description:
            "Det är enkelt att ta ett tryggt bolån hos oss. Vi ger alltid individuell ränta, ansök för att se vilken du får.",
        url: (
            { type, price, maintenance, year, name, fee, imageUrl },
            information,
        ) => {
            const objectType = type === "house" ? 1 : 2;
            const yearlyMaintenance = maintenance * 12;
            const cmp = information.campaignId
                ? `${information.campaignId}&`
                : "";
            return `https://www.nordea.se/privat/produkter/bolan/bolanekalkyl.html?${cmp}berakobjekt=${objectType}&pris=${price}&driftskostnad=${yearlyMaintenance}&avgift=${fee}&byggnadsar=${year}&adress=${name}&bild=${imageUrl}`;
        },
    },
    {
        id: Bank.Danskebank,
        label: "Danske Bank",
        description:
            "Hos Danske Bank får du personlig rådgivning och förmånlig bolåneränta.",
        url: (_, information) => {
            const cmp = information.campaignId
                ? "?" + information.campaignId
                : "";
            return `https://danskebank.se/privat/produkter/bolan${cmp}`;
        },
    },
];

export function getBankOffer(): IBankInformation[] {
    const offer = config.get<IBankInformation[]>("offer", null);
    if (offer === null || !Array.isArray(offer)) return null;

    return offer;
}

export function getOffer(): IOffer {
    const offer = config.get<IOffer>("offer", null);
    if (offer === null || !isObjectFunction(offer.onClick)) return null;

    return offer;
}
