import * as React from "react";

import classNames from "classnames";
import { useState } from "react";
import { AccessibleLi } from "./accessibility/accessible_components";

export interface ITabsProps {
    value?: string;
    onClick?: (label: string) => void;
    children: React.ReactElement[];
}

export default function Tabs(props: ITabsProps) {
    const { children, onClick, value } = props;
    const start = value
        ? children.findIndex((x) => x.props.value === value)
        : 0;
    const [index, setIndex] = useState(start);

    return (
        <div className="tabs">
            <ol role="tablist">
                {children.map((child: React.ReactElement, i: number) => {
                    const { title, value, name } = child.props;
                    if (!title)
                        throw new Error(
                            "Tab child must have label property set",
                        );

                    const onClickEvent = () => {
                        onClick && onClick(value);
                        setIndex(i);
                    };

                    const className = classNames({
                        active: index === i,
                    });

                    if (child.type === Tab) {
                        return React.cloneElement(child, {
                            key: i,
                            className,
                            onClick: onClickEvent,
                        });
                    }

                    return (
                        <Tab
                            key={i}
                            name={name}
                            value={value}
                            title={title}
                            className={className}
                            onClick={onClickEvent}
                        />
                    );
                })}
            </ol>
            <div className="content">{children[index].props.children}</div>
        </div>
    );
}

export interface ITabProp {
    className?: string;
    title: string;
    onClick?: () => void;
    children?: React.ReactNode;
    value: string;
    name: string;
}

export function Tab(props: ITabProp) {
    const { className, onClick, title, name } = props;

    return (
        <AccessibleLi
            className={className}
            onClick={onClick}
            data-cy={name}
            role="tab"
        >
            {title}
        </AccessibleLi>
    );
}
