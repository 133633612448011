import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import type { IRootState } from "../../reducers/root-reducer";
import { getCapital, getTotalHousingCost } from "../../selectors/housings";
import { toCurrency } from "../../utils/format";
import CapitalField from "../prospect/fields/capital-field";
import IncomeField from "../prospect/fields/income-field";
import config from "react-global-configuration";
import { isFunction } from "../../utils/helpers";
import {
    LeadsInput,
    MiniCalculatorInput,
    trackMiniCalculator,
    trackMiniLead,
} from "../../utils/analytics";
import { AccessibleButton } from "../accessibility/accessible-components";
import { useTracking } from "react-tracking";

export default function MiniFieldsComponent() {
    const { t } = useTranslation();
    const tracking = useTracking();

    const { totalCost, income, capital, type } = useSelector(
        (state: IRootState) => {
            return {
                totalCost: getTotalHousingCost(state.prospect),
                income: state.income,
                capital: getCapital(state.prospect),
                type: state.prospect.type,
            };
        },
    );

    function getOnLoanCommitmentFunction() {
        const { onLoanCommitment } = config.get("miniCalculator");
        if (!isFunction(onLoanCommitment)) return null;

        return onLoanCommitment;
    }

    function emitOnLoanCommitment() {
        const onLoanCommitment = getOnLoanCommitmentFunction();
        if (!isFunction(onLoanCommitment)) return;

        onLoanCommitment({ income, capital });

        tracking.trackEvent(
            trackMiniCalculator({
                action: MiniCalculatorInput.Loan_Commitment,
                label: MiniCalculatorInput.Loan_Commitment,
            }),
        );
        tracking.trackEvent(
            trackMiniLead({
                action: LeadsInput.Loan_Commitment,
                housingType: type,
            }),
        );
    }

    function getOnGoToCalculationFunction() {
        const { onGoToCalculation } = config.get("miniCalculator");
        if (!isFunction(onGoToCalculation)) return null;

        return onGoToCalculation;
    }

    function emitOnGoToCalculation() {
        const onGoToCalculation = getOnGoToCalculationFunction();
        if (!isFunction(onGoToCalculation)) return;

        onGoToCalculation({ income, capital });

        tracking.trackEvent(
            trackMiniCalculator({
                action: MiniCalculatorInput.Go_To_Calc,
                label: MiniCalculatorInput.Go_To_Calc,
            }),
        );
        tracking.trackEvent(
            trackMiniLead({
                action: LeadsInput.Go_To_Calc,
                housingType: type,
            }),
        );
    }

    return (
        <section className="mini-calculator">
            <div className="body">
                <div className="row">
                    <div className="container">
                        <h3>{t("mini:header")}</h3>
                        <CapitalField mini={true} />
                    </div>
                    <div className="container">
                        <IncomeField mini={true} />
                    </div>
                    <div className="container">
                        <h6>
                            {t("mini:total")}
                            <strong data-cy="mini-total-cost">
                                {toCurrency(totalCost)}
                            </strong>
                        </h6>
                        {getOnGoToCalculationFunction() && (
                            <AccessibleButton
                                onClick={emitOnGoToCalculation}
                                role="link"
                            >
                                {t("mini:go-to-calculations")}
                            </AccessibleButton>
                        )}
                        {getOnLoanCommitmentFunction() && (
                            <AccessibleButton
                                className="outline"
                                onClick={emitOnLoanCommitment}
                                role="link"
                            >
                                {t("mini:loan-commitment")}{" "}
                                <i className="icon icon-externallink" />
                            </AccessibleButton>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
