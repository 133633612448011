import * as React from "react";

import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState, setIncome } from "../../../reducers/root_reducer";
import Number from "../../form_controls/number_component";
import {
    MiniCalculatorInput,
    trackEngagement,
    trackMiniCalculator,
    UserInput,
} from "../../../utils/analytics";
import { HOUSEHOLD_INCOME_MAX, HOUSEHOLD_INCOME_STEP } from "../../../defaults";

export default function IncomeField({ mini = false }) {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();

    const { income, type } = useSelector((state: IRootState) => {
        return {
            income: state.income,
            type: state.prospect.type,
        };
    });

    function onChange(value: number) {
        dispatch(setIncome(value));
    }

    function onValueSet(value: number) {
        tracking.trackEvent(
            trackEngagement({
                action: UserInput.Household_Income,
                value: value.toString(),
                housingType: type,
            }),
        );

        if (!mini) return;

        tracking.trackEvent(
            trackMiniCalculator({
                action: MiniCalculatorInput.Income,
                label: MiniCalculatorInput.Income,
                value: income.toString(),
            }),
        );
    }

    return (
        <Number
            label={t("common:income")}
            unit="kr"
            ariaLabel={t("common:currency")}
            value={income}
            hint={t("hint:income")}
            max={HOUSEHOLD_INCOME_MAX}
            step={HOUSEHOLD_INCOME_STEP}
            name={mini ? "mini-income" : "income"}
            onChange={onChange}
            onValueSet={onValueSet}
        />
    );
}
