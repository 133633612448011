import { Moment } from "moment";

export function toCurrency(value, approximation = false, options = {}) {
    if (approximation) {
        const numberOfDigits = Math.round(value).toString().length;
        const significantDigits = Math.ceil(numberOfDigits / 2);
        options = Object.assign(options, {
            maximumSignificantDigits: significantDigits,
        });
    }

    const formatter = new Intl.NumberFormat(
        "sv-SE",
        Object.assign(
            {
                style: "currency",
                currency: "SEK",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
            options,
        ),
    );

    // Fix to solve bug in Internet Explorer where it always generates decimals when formatting as currency
    if (formatter.formatToParts) {
        const genericFormatter = formatter;
        const parts = genericFormatter.formatToParts(value);
        const filteredParts = parts
            .filter(
                (part) => part.type !== "fraction" && part.type !== "decimal",
            )
            .map((part) => part.value);
        return filteredParts.join("");
    }

    return formatter.format(value);
}

export function toDecimal(value, minDecimals, maxDecimals, options = {}) {
    const formatter = new Intl.NumberFormat(
        "sv-SE",
        Object.assign(
            {
                minimumFractionDigits: minDecimals,
                maximumFractionDigits: maxDecimals,
            },
            options,
        ),
    );

    return formatter.format(value);
}

export function userFormattedPercent(interest: number, decimals = 2): number {
    return Number((interest * 100).toFixed(decimals));
}

export function fromUserFormattedPercent(userInterest: number): number {
    return Number((userInterest / 100).toFixed(4));
}

export function formatDate(date: Moment): string {
    return date.format("MMMM YYYY");
}

export function roundWithPrecision(value: number, precision: number): number {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
}

export function capitalize(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}
