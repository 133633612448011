import * as React from "react";
import classnames from "classnames";
import i18next from "i18next";
import { AccessibleIdiomaticText } from "./accessibility/accessible_components";

interface IProps {
    container?: React.RefObject<unknown>;
}

interface IState {
    open: boolean;
}

export default class HintComponent extends React.Component<IProps, IState> {
    private wrapperNode = React.createRef<HTMLDivElement>();
    private hintNode: HTMLElement;

    constructor(props: IProps) {
        super(props);
        this.state = { open: false };
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    handleClick(event) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
            this.state.open ? this.close() : this.open();
        }
    }

    close() {
        this.setState({ open: false });
        document.removeEventListener("click", this.handleDocumentClick);
        document.removeEventListener("open-hint", this.handleDocumentClick);
    }

    open() {
        const openEvent = new CustomEvent("open-hint");
        document.dispatchEvent(openEvent);

        this.setState({ open: true });
        document.addEventListener("click", this.handleDocumentClick);
        document.addEventListener("open-hint", this.handleDocumentClick);
    }

    handleDocumentClick(event) {
        const isInsideHint = event.target === this.hintNode;
        if (!isInsideHint) {
            this.close();
        }
    }

    render() {
        let style;

        if (this.state.open && this.hintNode) {
            let parentContainer;

            const miniWidget = document.querySelector(".widget-container.mini");

            if (miniWidget) {
                parentContainer = miniWidget.contains(this.hintNode)
                    ? document.querySelectorAll(".widget-container")[0]
                    : document.querySelectorAll(".widget-container")[1];
            } else {
                parentContainer = document.querySelector(".widget-container");
            }

            style = {
                left: "0px",
                top: "0px",
                maxWidth: "340px",
            };

            if (parentContainer) {
                const bounds = parentContainer.getBoundingClientRect();
                const wrapperBounds =
                    this.wrapperNode.current.getBoundingClientRect();

                const hintWidth = Math.min(
                    this.hintNode.getBoundingClientRect().width,
                    bounds.width,
                );
                const hintHeight = this.hintNode.getBoundingClientRect().height;
                const hintLeft = wrapperBounds.left - bounds.left;
                const hintTop = bounds.top - wrapperBounds.top;

                const centered = hintLeft - hintWidth / 2;
                const left = 0;
                const right = bounds.width - hintWidth;
                const bottom = bounds.height - hintHeight + hintTop;

                const actualLeft = Math.min(Math.max(left, centered), right);
                const actualTop = Math.min(Math.max(0, hintTop), bottom);

                style = {
                    left: actualLeft - hintLeft + "px",
                    top: actualTop + "px",
                    maxWidth: bounds.width - 30 + "px",
                };
            }
        }

        return (
            <div className="hint">
                <AccessibleIdiomaticText
                    className="icon icon-info"
                    onClick={this.handleClick.bind(this)}
                    onKeyPress={this.handleClick.bind(this)}
                    aria-label={i18next.t("hint:button-info")}
                    data-cy="hint-icon"
                />
                <div className="hint-wrapper" ref={this.wrapperNode}>
                    <p
                        data-cy="hint-content"
                        ref={(el) => {
                            this.hintNode = el;
                            if (el && style) {
                                el.style.setProperty(
                                    "left",
                                    style.left,
                                    "important",
                                );
                                el.style.setProperty(
                                    "top",
                                    style.top,
                                    "important",
                                );
                                el.style.setProperty(
                                    "max-width",
                                    style.maxWidth,
                                    "important",
                                );
                            }
                        }}
                        className={classnames("hint-content", {
                            "hint-open": this.state.open,
                        })}
                    >
                        {this.props.children}
                    </p>
                </div>
            </div>
        );
    }
}
