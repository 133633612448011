import * as React from "react";

import { useTracking } from "react-tracking";

import { LeadsInput, trackMainLead } from "../../utils/analytics";
import { useTranslation } from "react-i18next";

import type { IRootState } from "../../reducers/root-reducer";
import { useSelector } from "react-redux";
import { getHousingInformation } from "../../selectors/housings";
import { getOffer } from "../../utils/offer";
import { AccessibleButton } from "../accessibility/accessible-components";

export default function OfferComponent() {
    const offer = getOffer();
    if (!offer) return null;

    const { t } = useTranslation();
    const tracking = useTracking();
    const { type, info } = useSelector((state: IRootState) => {
        return {
            type: state.prospect.type,
            info: getHousingInformation(state.prospect),
        };
    });

    function onClick() {
        tracking.trackEvent(
            trackMainLead({
                action: LeadsInput.Loan_Commitment,
                housingType: type,
            }),
        );

        offer.onClick(info);
    }

    return (
        <div className="offer">
            <div className="offer-logo" />
            <h3>{offer.header || t("offer:header")}</h3>
            <p>
                <small>{offer.description || t("offer:description")}</small>
            </p>
            <AccessibleButton onClick={onClick}>
                {t("offer:lead")}
            </AccessibleButton>
        </div>
    );
}
