import * as React from "react";
import track from "react-tracking";
import config from "react-global-configuration";
import { FocusVisibleManager } from "use-focus-visible";

import MiniFieldsComponent from "./mini/mini_fields_component";
import { EventCategories, WidgetEventAction } from "../utils/analytics";
import type { TrackingData } from "@econans/tracking";

@track(
    { page: "MoveCalculator" },
    {
        dispatchOnMount: (): TrackingData => ({
            action: WidgetEventAction.MiniWidget_Loaded,
            tag: EventCategories.Widget,
            label: EventCategories.Widget,
        }),
        dispatch: (data) => config.get("onEvent")(data),
    },
)
export default class MiniMoveCalculatorComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <FocusVisibleManager>
                <div className="widget-container mini">
                    <MiniFieldsComponent />
                </div>
            </FocusVisibleManager>
        );
    }
}
