import { minInstallment } from "../functions/installment";
import config from "react-global-configuration";

import {
    DOWNPAYMENT_RATE,
    EXISTING_LTV_RATIO,
    LOAN_INSTALLMENT,
    LOAN_INTEREST,
} from "../defaults";

export interface ArrayValue {
    index: number;
    value: number;
}

export interface ILoan {
    interest: number;
    amount: number;
    installment: number;
}

export function createLoan({
    amount,
    interest = undefined,
    installment,
}): ILoan {
    const defaultInterest = config.get<number>(
        "defaultHousingInterest",
        LOAN_INTEREST,
    );
    return {
        interest: interest || defaultInterest,
        amount,
        installment,
    };
}

export function createProspectHousingLoan(price: number, income: number) {
    const downpaymentRate = config.get<number>(
        "defaultDownpaymentRate",
        DOWNPAYMENT_RATE,
    );
    const ltvRatio = 1 - downpaymentRate;
    const amount = Math.round(ltvRatio * price);

    const installment = minInstallment(price, amount, income);

    return createLoan({
        amount,
        installment,
    });
}

export function createExistingHousingLoan(price: number) {
    const amount = Math.round(EXISTING_LTV_RATIO * price);

    return createLoan({
        amount,
        installment: LOAN_INSTALLMENT,
    });
}
