import * as React from "react";

import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import { trackEngagement, UserInput } from "../../../utils/analytics";
import LoanAmountField from "../../loans/loan_amount_field";
import LoanInterestField from "../../loans/loan_interest_field";
import LoanInstallmentField from "../../loans/loan_installment_field";
import {
    IRootState,
    setProspectLoanAmount,
    setProspectLoanInstallment,
    setProspectLoanInterest,
} from "../../../reducers/root_reducer";
import { fromUserFormattedPercent } from "../../../utils/format";
import { isLoanToValueHigh } from "../../../selectors/housings";
import classNames from "classnames";

export default function LoanField() {
    const tracking = useTracking();
    const dispatch = useDispatch();

    const { prospect, loan, income, type, highLTV } = useSelector(
        (state: IRootState) => {
            return {
                prospect: state.prospect,
                loan: state.prospect.loans[0],
                income: state.income,
                type: state.prospect.type,
                highLTV: isLoanToValueHigh(state.prospect),
            };
        },
    );

    return (
        <>
            <LoanAmountField
                housing={prospect}
                loan={loan}
                className={classNames({ alert: highLTV })}
                onChange={(value) => dispatch(setProspectLoanAmount(value))}
                onValueSet={(value) =>
                    tracking.trackEvent(
                        trackEngagement({
                            action: UserInput.Prospect_Loan,
                            value: value.toString(),
                            housingType: type,
                        }),
                    )
                }
            />
            <LoanInterestField
                housing={prospect}
                loan={loan}
                onChange={(value) =>
                    dispatch(
                        setProspectLoanInterest(
                            fromUserFormattedPercent(value),
                        ),
                    )
                }
                onValueSet={(value) =>
                    tracking.trackEvent(
                        trackEngagement({
                            action: UserInput.Prospect_Interest,
                            value: Math.round(value * 100).toString(),
                            housingType: type,
                        }),
                    )
                }
            />
            <LoanInstallmentField
                housing={prospect}
                loan={loan}
                income={income}
                useAmortizationRequirement={true}
                onChange={(value) =>
                    dispatch(setProspectLoanInstallment(value))
                }
                onValueSet={(value) =>
                    tracking.trackEvent(
                        trackEngagement({
                            action: UserInput.Prospect_Amortization,
                            value: value.toString(),
                            housingType: type,
                        }),
                    )
                }
            />
        </>
    );
}
