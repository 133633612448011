import * as React from "react";

import { useTracking } from "react-tracking";

import { trackBankLead, trackInEconansBackend } from "../../utils/analytics";
import {
    BANK_OPTIONS,
    getBankOffer,
    IBankInformation,
    OnStringCallback,
} from "../../utils/offer";
import { useTranslation } from "react-i18next";

import { IRootState } from "../../reducers/root_reducer";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Carousel, { ICarouselItem } from "../carousel_component";
import { useMemo } from "react";
import { shuffleArray } from "../../utils/random";
import { AccessibleAnchor } from "../accessibility/accessible_components";
import MoveCalculatorWidget from "../../move-calculator";
import config from "react-global-configuration";
import { IEconansLeadsTracking } from "../../models/config";

export default function BankOfferComponent() {
    const econansLeadsTracking: IEconansLeadsTracking = config.get(
        "econansLeadsTracking",
    );
    let offer = getBankOffer();
    if (!offer) return null;

    const { t } = useTranslation();
    const tracking = useTracking();
    const { prospect } = useSelector((state: IRootState) => {
        return {
            prospect: state.prospect,
        };
    });

    useMemo(() => {
        offer = shuffleArray(offer);
    }, []);

    function onClick(id: string, url: string, copyText: string): void {
        tracking.trackEvent(trackBankLead({ action: id, copyText }));

        const urlObject = new URL(url);

        if (econansLeadsTracking) {
            trackInEconansBackend(
                econansLeadsTracking.origin,
                id,
                urlObject,
                MoveCalculatorWidget.sessionId,
            );
        }

        window.open(urlObject, "_blank");
    }

    function merge(option: IBankInformation): IBankInformation {
        const fromDefault = BANK_OPTIONS.find((x) => x.id === option.id);

        if (!fromDefault) {
            return option;
        }

        return {
            id: option.id,
            label: option.label || fromDefault.label,
            campaignId: option.campaignId || fromDefault.campaignId,
            description: option.description || fromDefault.description,
            url: option.url || fromDefault.url,
        };
    }

    const description = (bank: IBankInformation) =>
        typeof bank.description === "string"
            ? bank.description
            : (bank.description as OnStringCallback)(prospect, bank);
    const url = (bank: IBankInformation) =>
        typeof bank.url === "string"
            ? bank.url
            : (bank.url as OnStringCallback)(prospect, bank);

    const items: ICarouselItem[] = offer
        .map(merge)
        .map((bank: IBankInformation) => ({
            id: bank.id,
            label: bank.label,
            content: (
                <div className="offer bank">
                    <i
                        className={classNames("logo", bank.id)}
                        aria-label={bank.id}
                    />
                    <p>
                        <small>{description(bank)}</small>
                    </p>
                    <AccessibleAnchor
                        className="button"
                        onClick={() => {
                            onClick(bank.id, url(bank), description(bank));
                        }}
                        role="link"
                        rel="sponsored"
                    >
                        {t("offer:lead")}{" "}
                        <i className="icon icon-externallink"></i>
                    </AccessibleAnchor>
                </div>
            ),
        }));

    return <Carousel items={items} />;
}
