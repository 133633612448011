import classNames from "classnames";
import * as React from "react";
import { isFirefox } from "../../utils/browser";
import { toDecimal } from "../../utils/format";
import { processMarkdown } from "../../utils/markdown_processor";
import HintComponent from "../hint_component";

interface IProps {
    label: string;
    unit?: string;
    ariaLabel?: string;
    value: number;
    disabled?: boolean;
    decimal: boolean;
    min: number;
    max?: number;
    step: number;
    name: string;
    className?: string;
    preserveMin?: boolean;
    onChange: (value: number) => void;
    onValueSet?: (value: number) => void;
    hint?: string;
    preInputValue?: string;
}

interface IState {
    editing: boolean;
    value: string | number;
}

export default class NumberComponent extends React.Component<IProps, IState> {
    static defaultProps = {
        unit: "kr",
        min: 0,
        step: 1,
        decimal: false,
        className: "",
    };

    rangeMin: number;
    rangeMax: number;
    sliderRef: HTMLInputElement;

    constructor(props: IProps) {
        super(props);
        this.updateRange(props);
        this.state = { editing: false, value: props.value };
    }

    updateRange(props) {
        const nStepsToMin = Math.floor(props.min / props.step);
        const nStepsToMax = Math.ceil(props.max / props.step);
        this.rangeMin = Math.max(nStepsToMin * props.step, 0);
        this.rangeMax = nStepsToMax * props.step;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateRange(nextProps);
    }

    componentDidMount() {
        this.onSliderChangeShadow();
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value });
        }
        this.onSliderChangeShadow();
    }

    // Triggered both on change and input due to interaction with Cypress.
    // See https://github.com/cypress-io/cypress/issues/1570
    onTextChange(event) {
        const value = event.target.value;
        const containsNonNumericCharacters = /[^\d.,]/.test(value);
        if (!containsNonNumericCharacters) {
            this.setState({ value });
        }
    }

    onTextBlur(event) {
        const { state, props } = this;
        const { editing } = state;

        const rawValue = event.target.value;
        // Numbers in JS use "," as separator while some keyboards use ","
        const value = parseFloat(rawValue.replace(",", ".")) || this.props.min;
        const { onChange, onValueSet, preserveMin, min } = props;

        // NOTE: by design higher-than allowed values are not reverted.
        if (preserveMin && value < min) {
            this.setState({ value: min });
            onChange(min);
        } else {
            this.setState({ value });
            onChange(value);
        }

        if (editing) {
            this.setState({ editing: false });
        }
        onValueSet && onValueSet(value);
    }

    onSliderChange(event) {
        const { preserveMin, min } = this.props;
        let value = Number(event.target.value);
        if (preserveMin && value < min) {
            value = min;
        }
        this.props.onChange(value);
    }

    onSliderChangeShadow() {
        const thumb_position = Math.round(
            (98 * (parseFloat(this.sliderRef.value) - this.rangeMin)) /
                (this.rangeMax - this.rangeMin) +
                1,
        );

        this.sliderRef.style.setProperty(
            "background-size",
            `${thumb_position}% 100%`,
            "important",
        );
    }

    onSliderBlur(event) {
        const { onValueSet } = this.props;
        onValueSet && onValueSet(parseFloat(event.target.value));
    }

    triggerEditing(e) {
        this.setState({ editing: true });
        setTimeout(() => e.target.select(), 50);
    }

    render() {
        const {
            label,
            name,
            value,
            step,
            unit,
            ariaLabel,
            hint,
            preInputValue,
            className,
            disabled,
        } = this.props;

        const format = () => {
            if (this.state.editing || this.props.decimal) {
                return this.state.value;
            } else {
                return toDecimal(this.state.value, 0, 0);
            }
        };

        let additionalAttributes = {};
        // set decimal here to get keyboard with comma etc on phone
        if (this.props.decimal) {
            additionalAttributes = { inputMode: "decimal" };
        } else if (!isFirefox()) {
            // integer input
            additionalAttributes = { pattern: "\\d*" };
        }

        const blurOnEnter = (event) => {
            if (event.keyCode === 13) {
                event.target.blur();
            }
        };

        return (
            <div className="number-component">
                <div className="number">
                    <label>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: processMarkdown(label),
                            }}
                        ></span>
                        {hint && <HintComponent>{hint}</HintComponent>}
                        <strong>{preInputValue}</strong>
                    </label>
                    <div className="input-wrapper">
                        <input
                            type={"text"}
                            name={name}
                            value={format()}
                            aria-label={format().toString()}
                            className={classNames(className, { disabled })}
                            onChange={this.onTextChange.bind(this)}
                            onBlur={this.onTextBlur.bind(this)}
                            onKeyUp={blurOnEnter}
                            step={step}
                            min={this.rangeMin}
                            max={this.rangeMax}
                            disabled={disabled}
                            onFocus={this.triggerEditing.bind(this)}
                            {...additionalAttributes}
                        />
                        <span className="unit" aria-label={ariaLabel}>
                            {unit}
                        </span>
                    </div>
                </div>
                <div className="slider">
                    <input
                        type="range"
                        name={name}
                        min={this.rangeMin}
                        max={this.rangeMax}
                        className={classNames({ disabled })}
                        disabled={disabled}
                        step={step}
                        value={value}
                        onInput={this.onSliderChange.bind(this)}
                        onMouseUp={this.onSliderBlur.bind(this)}
                        onTouchEnd={this.onSliderBlur.bind(this)}
                        ref={(el) => (this.sliderRef = el)}
                        onChange={() => {
                            return void 0;
                        }}
                    />
                </div>
            </div>
        );
    }
}
