import * as React from "react";

import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    IRootState,
    setProspectEstimatedPrice,
} from "../../../reducers/root_reducer";
import Number from "../../form_controls/number_component";
import { trackEngagement, UserInput } from "../../../utils/analytics";
import { getMinPrice, getMaxPrice } from "../../../selectors/housings";

export default function EstimatedPriceField() {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();

    const { price, minPrice, maxPrice, isFixedPrice, type } = useSelector(
        (state: IRootState) => {
            return {
                type: state.prospect.type,
                minPrice: getMinPrice(state.prospect),
                maxPrice: getMaxPrice(state.prospect),
                price: state.prospect.price || 0,
                isFixedPrice: state.prospect.isFixedPrice,
            };
        },
    );

    function onChange(value: number) {
        dispatch(setProspectEstimatedPrice(value));
    }

    function onValueSet(value: number) {
        tracking.trackEvent(
            trackEngagement({
                action: UserInput.Prospect_Price,
                value: value.toString(),
                housingType: type,
            }),
        );
    }

    return (
        <Number
            label={
                isFixedPrice
                    ? t("prospect:price")
                    : t("prospect:estimated-price")
            }
            unit="kr"
            ariaLabel={t("common:currency")}
            value={price}
            disabled={isFixedPrice}
            min={minPrice}
            max={maxPrice}
            step={50000}
            name="prospect[price]"
            onChange={onChange}
            onValueSet={onValueSet}
        />
    );
}
