import type { TrackingData } from "@econans/tracking";
import { HousingTypes } from "../models/housing";

export interface IEvent {
    category: string;
    action: string;
    value?: number;
    label?: string;
}

export enum EventCategories {
    Engagement = "engagement",
    Leads = "leads",
    Bank_Leads = "bank-leads",
    Widget = "widget",
    Mini_Calculator = "mini-calculator",
}

export enum WidgetEventAction {
    Widget_Loaded = "widget_loaded",
    MiniWidget_Loaded = "mini-widget_loaded",
}

export enum MiniCalculatorInput {
    Housing_Capital = "set_housing_capital",
    Income = "set_household_income",
    Go_To_Calc = "go_to_calculation",
    Loan_Commitment = "loan_commitment",
}

export enum UserInput {
    Prospect_Price = "set_prospect_price",
    Prospect_Loan = "set_prospect_loan",
    Prospect_Interest = "set_prospect_interest",
    Prospect_Amortization = "set_prospect_amortization",
    Housing_Capital = "set_housing_capital",
    Household_Income = "set_household_income",
    Existing_Price = "set_existing_price",
    Existing_Loan = "set_existing_loan",
    Existing_Interest = "set_existing_interest",
    Existing_Amortization = "set_existing_amortization",
    Existing_Fee = "set_existing_fee",
    Existing_Maintenance = "set_existing_maintenance",
    Capital_Gain_Tax = "toggle_capital_gain_tax",
    Original_Price = "set_original_price",
    Renovation_Cost = "set_renovation_cost",
    Amount_To_Pay = "set_amount_to_pay",
    Already_Saved = "set_already_saved",
}

export enum LeadsInput {
    House_Validation = "on_house_valuation",
    Loan_Commitment = "on_loan_commitment",
    Go_To_Calc = "go_to_calculation",
}

export function trackEngagement({
    action,
    value,
    housingType,
}: {
    action: UserInput;
    value: string;
    housingType: HousingTypes;
}): TrackingData {
    return {
        action,
        tag: EventCategories.Engagement,
        label: housingType,
        value: value.toString(),
    };
}

export function trackLead({
    action,
    housingType,
}: {
    action: LeadsInput;
    housingType: HousingTypes;
}): TrackingData {
    return {
        action,
        tag: EventCategories.Leads,
        label: housingType,
    };
}

export function trackBankLead({
    action,
    copyText,
}: {
    action: string;
    copyText: string;
}): TrackingData {
    return {
        action,
        tag: EventCategories.Bank_Leads,
        label: copyText,
    };
}

export function trackMiniCalculator({
    action,
    label,
    value,
}: {
    action: MiniCalculatorInput;
    label: string;
    value?: string;
}): TrackingData {
    return {
        action,
        tag: EventCategories.Mini_Calculator,
        label,
        value,
    };
}

export async function trackInEconansBackend(
    client: string,
    leadName: string,
    leadUrl: URL,
    sessionId: string,
): Promise<void> {
    const bodyContent = {
        widget: "moving-calculator",
        client,
        leadName,
        leadUrl: leadUrl.host,
        sessionId,
    };

    const apiEndpoint = process.env.ECONANS_BACKEND_TRACKING_URL;
    const apiKey = process.env.ECONANS_API_KEY;

    const headers = { "api-key": apiKey, "Content-Type": "application/json" };
    const body = JSON.stringify(bodyContent);

    try {
        await fetch(apiEndpoint, {
            method: "post",
            headers,
            body,
        });
    } catch {
        // No action
    }

    return;
}
