import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MONTHS_IN_YEAR } from "../../../defaults";
import {
    installmentThresholds,
    requiredMinInstallmentRate,
} from "../../../functions/installment";
import { IRootState } from "../../../reducers/root_reducer";
import {
    getLoanToValue,
    getTotalLoanAmount,
    getTotalLoanInstallment,
} from "../../../selectors/loans";
import { userFormattedPercent } from "../../../utils/format";
import { insightsModule } from "../../../utils/module_store";

const FutureAmortizationInsight = () => {
    const { t } = useTranslation();
    const { price, income, ltv, currentLoans, currentInstallment } =
        useSelector((state: IRootState) => {
            return {
                price: state.prospect.price,
                income: state.income,
                ltv: getLoanToValue(state.prospect),
                currentLoans: getTotalLoanAmount(state.prospect),
                currentInstallment: getTotalLoanInstallment(state.prospect),
            };
        });

    const lowerThresholds = installmentThresholds(price, income).filter(
        (threshold) => threshold < ltv,
    );

    const insight =
        lowerThresholds.length > 0
            ? getInsight(lowerThresholds, price, income)
            : t("insight:no-amortization-requirements");

    return (
        <p
            dangerouslySetInnerHTML={{
                __html: insight,
            }}
        ></p>
    );

    function getInsight(lowerThresholds: number[], price, income): string {
        const nextLowerInstallmentThreshold = Math.max(...lowerThresholds);
        const loanToAimfor = price * nextLowerInstallmentThreshold;

        const amountToPayoff = currentLoans - loanToAimfor;

        const nextInstallmentMinRate = requiredMinInstallmentRate(
            price,
            nextLowerInstallmentThreshold,
            income,
        );

        const currentYearlyInstallmentAmount =
            currentInstallment * MONTHS_IN_YEAR;
        const yearsUntilPayedOff = Math.ceil(
            amountToPayoff / currentYearlyInstallmentAmount,
        );

        return t("insight:future-amortization", {
            yearsUntilPayedOff: `<strong>${yearsUntilPayedOff} år</strong>`,
            nextRate: `<b>${userFormattedPercent(
                nextInstallmentMinRate,
            )} %</b>`,
        });
    }
};

export default insightsModule("future-amortization")(FutureAmortizationInsight);
