import * as React from "react";

import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import { trackEngagement, UserInput } from "../../../utils/analytics";
import LoanAmountField from "../../loans/loan_amount_field";
import LoanInterestField from "../../loans/loan_interest_field";
import LoanInstallmentField from "../../loans/loan_installment_field";
import {
    addExistingLoan,
    IRootState,
    removeExistingLoan,
    setExistingLoanAmount,
    setExistingLoanInstallment,
    setExistingLoanInterest,
} from "../../../reducers/root_reducer";
import { fromUserFormattedPercent } from "../../../utils/format";
import { ILoan } from "../../../models/loan";
import { useTranslation } from "react-i18next";
import { AccessibleAnchor } from "../../accessibility/accessible_components";

export default function LoansField() {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();

    const { existing, loans, income, type } = useSelector(
        (state: IRootState) => {
            return {
                existing: state.existing,
                loans: state.existing.loans,
                income: state.income,
                type: state.existing.type,
            };
        },
    );

    return (
        <>
            {loans.map((loan: ILoan, index: number) => {
                const isFirstItem = index === 0;
                const isLastItem = index === loans.length - 1;
                return (
                    <div key={index} data-cy="housing-loan">
                        <LoanAmountField
                            housing={existing}
                            loan={loan}
                            onChange={(value) =>
                                dispatch(
                                    setExistingLoanAmount({ index, value }),
                                )
                            }
                            onValueSet={(value) =>
                                tracking.trackEvent(
                                    trackEngagement({
                                        action: UserInput.Existing_Loan,
                                        value: value.toString(),
                                        housingType: type,
                                    }),
                                )
                            }
                        />
                        <LoanInterestField
                            housing={existing}
                            loan={loan}
                            onChange={(value) =>
                                dispatch(
                                    setExistingLoanInterest({
                                        index,
                                        value: fromUserFormattedPercent(value),
                                    }),
                                )
                            }
                            onValueSet={(value) =>
                                tracking.trackEvent(
                                    trackEngagement({
                                        action: UserInput.Existing_Interest,
                                        value: Math.round(
                                            value * 100,
                                        ).toString(),
                                        housingType: type,
                                    }),
                                )
                            }
                        />
                        <LoanInstallmentField
                            housing={existing}
                            loan={loan}
                            income={income}
                            showRatio={isFirstItem}
                            onChange={(value) =>
                                dispatch(
                                    setExistingLoanInstallment({
                                        index,
                                        value,
                                    }),
                                )
                            }
                            onValueSet={(value) =>
                                tracking.trackEvent(
                                    trackEngagement({
                                        action: UserInput.Existing_Amortization,
                                        value: value.toString(),
                                        housingType: type,
                                    }),
                                )
                            }
                        />

                        <div className="cell">
                            <AccessibleAnchor
                                onClick={() => dispatch(addExistingLoan())}
                                data-cy="add-loan"
                                role="button"
                            >
                                <i className="icon icon-add"></i>
                                {t("loan:add-loan")}
                            </AccessibleAnchor>
                            {!isFirstItem && (
                                <AccessibleAnchor
                                    onClick={() =>
                                        dispatch(removeExistingLoan(index))
                                    }
                                    data-cy="remove-loan"
                                    role="button"
                                >
                                    <i className="icon icon-delete"></i>
                                    {t("loan:remove-loan")}
                                </AccessibleAnchor>
                            )}
                        </div>

                        {!isLastItem && <div className="divider"></div>}
                    </div>
                );
            })}
        </>
    );
}
