import * as React from "react";

import { useTracking } from "react-tracking";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState, setExistingFee } from "../../../reducers/root_reducer";
import Number from "../../form_controls/number_component";
import { trackEngagement, UserInput } from "../../../utils/analytics";
import { FEE_STEP, FEE_MAX } from "../../../defaults";

export default function FeeField() {
    const { t } = useTranslation();
    const tracking = useTracking();
    const dispatch = useDispatch();

    const { fee, type } = useSelector((state: IRootState) => {
        return {
            fee: state.existing.fee,
            type: state.prospect.type,
        };
    });

    function onChange(value: number) {
        dispatch(setExistingFee(value));
    }

    function onValueSet(value: number) {
        tracking.trackEvent(
            trackEngagement({
                action: UserInput.Existing_Fee,
                value: value.toString(),
                housingType: type,
            }),
        );
    }

    return (
        <Number
            label={t("existing:fee")}
            unit="kr"
            ariaLabel={t("common:currency")}
            value={fee}
            max={FEE_MAX}
            step={FEE_STEP}
            name="existing[fee]"
            onChange={onChange}
            onValueSet={onValueSet}
        />
    );
}
