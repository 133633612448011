import * as React from "react";
import config from "react-global-configuration";
import { isObjectFunction } from "../../utils/helpers";
import { useTracking } from "react-tracking";
import { LeadsInput, trackMainLead } from "../../utils/analytics";
import { useSelector } from "react-redux";

import type { IRootState } from "../../reducers/root-reducer";
import { useTranslation } from "react-i18next";
import type { HousingCallback } from "../../models/config";
import { getHousingInformation } from "../../selectors/housings";
import { AccessibleButton } from "../accessibility/accessible-components";

export default function ValuationComponent() {
    const { t } = useTranslation();
    const tracking = useTracking();

    const onEvaluation = config.get<HousingCallback>("onEvaluation", null);
    if (!isObjectFunction(onEvaluation)) return <></>;

    const { type, info } = useSelector((state: IRootState) => {
        return {
            type: state.prospect.type,
            info: getHousingInformation(state.existing),
        };
    });

    function onClick() {
        tracking.trackEvent(
            trackMainLead({
                action: LeadsInput.House_Validation,
                housingType: type,
            }),
        );

        onEvaluation(info);
    }

    return (
        <AccessibleButton onClick={onClick}>
            {t("common:valuation")}
        </AccessibleButton>
    );
}
